@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
.model_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    background: none;
    border: 0px;
    font-family: "Geologica", sans-serif;
	font-weight: 100;
    font-size: 14px;
    padding-bottom: 20px;
    margin-left: 26px;
}
.image_model {
    width: 100px;
    height: 100px;
    margin-top: 20px;
    border-radius: 50px;
}
@media(max-width: 1460px) {
    .model_wrapper {
        margin-left: 30px;
    }
}
@media (max-width: 1200px) {
    .model_wrapper {
        margin-left: 35px;
    }
}
@media (max-width: 700px) {
    .model_wrapper {
        margin-left: 30px;
    }
}
@media (max-width: 500px) {
    .model_wrapper {
        margin-left: 35px;
    }
}