.category_wrapper {
    width: 300px;
    margin: 0px 30px 100px;
    position: relative;
    display: flex;
    justify-content: center;
}
.opacity_block {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0.5;
    background-color: #fff;
}
.category_image {
    width: 300px;
}
.category_title {
    font-size: 30px;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 40%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 100;
}
@media(max-width:1100px) {
    .category_wrapper {
        width: 250px;
        margin: 0px 10px 50px;
    }
    .category_image {
        width: 250px;
    }
    .category_title {
        font-size: 25px;
    }
}
@media(max-width:820px) {
    .category_wrapper {
        width: 200px;
    }
    .category_image {
        width: 200px;
    }
    .category_title {
        font-size: 20px;
    }
}
@media(max-width:670px) {
    .category_wrapper {
        width: 150px;
    }
    .category_image {
        width: 150px;
    }
    
    .category_title {
        font-size: 18px;
    }
}
@media(max-width:520px) {
    .category_wrapper {
        max-width: 100%;
        width: auto;
        margin: 20px 25px;
    }
    .category_image {
        max-width: 100%;
        width: auto;
    }
    
    .category_title {
        top: 45%;
        font-size: 30px;
    }
}