@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
.model_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    border: 0;
    background:none;
    font-family: "Geologica", sans-serif;
	font-weight: 100;
    font-size: 13px;
    padding-bottom: 20px;
}
@media(max-width:500px) {
    .model_wrapper {
        font-size: 11px;
        margin-left: 30px;
    }
}
.image_model {
    width: 100px;
    height: 100px;
    margin-top: 20px;
    border-radius: 100px;
    cursor: pointer;
}
