@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap");

.header_wrapper {
	display: block;
	text-decoration: none;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: #fff;
	border-bottom: 1px solid #e6e6e6;
	z-index: 99999;
}
a {
	color: #000;
	text-decoration: none;
}
.contacts {
	display: none;
	align-items: center;
	justify-content: end;
	padding-left: 40px;
	padding-right: 40px;
	background-color: #f8f8f8;
	border-bottom: 1px solid #e6e6e6;
}
.contacts > * {
	padding: 10px;
}
.nav_menu {
	padding-left: 40px;
	padding-right: 40px;
	font-family: "Geologica", sans-serif;
	font-weight: 200;
}
.menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 80px;
}
.menu_button {
	margin-left: auto;
	margin-right: auto;
	font-size: 18px;
	padding-left: 100px;
}
.menu_button span:hover {
}
.menu_button a .active {
	border: 0;
}
.menu_button a {
	display: inline-block;
	position: relative;
}
.menu_button a::before {
	content: "";
	position: absolute;
	display: block;
	width: 100%;
	height: 1px;
	bottom: -1px;
	left: 0;
	background-color: #000000;
	transform: scaleX(0);
	transform-origin: top left;
	transition: transform 0.3s ease;
}
.menu_button a:hover::before {
	transform: scaleX(1);
}
.menu_button > *:not(:last-child) {
	margin-right: 65px;
}
.logo {
	display: flex;
	align-items: center;
	position: absolute;
}
.logo > *:first-child {
	padding-right: 10px;
}
.logo span {
	color: #000;
	font-size: 18px;
	font-weight: 100;
	font-family: "Times New Roman", Times, serif !important;
	line-height: 0.9;
	text-align: center;
}
.logo span svg {
	width: 8px;
	height: 8px;
	margin-right: -5px;
	margin-bottom: 2px;
}
.menu_burger {
	position: absolute;
	left: 20px;
	display: none;
	border: none;
	background: transparent;
	width: 40px;
	height: 26px;
	cursor: pointer;
}
.menu_burger::before,
.menu_burger::after {
	content: "";
	left: 0;
	position: absolute;
	display: block;
	width: 100%;
	height: 3px;
	border-radius: 10px;
	background: #000;
}
.menu_burger::before {
	top: 0;
	box-shadow: 0 11px 0 #000;
}
.menu_burger::after {
	bottom: 0;
}
.menu_burgerOpen {
	display: none;
	border-bottom: 1px solid #e6e6e6;
}
.menu_burgerClose {
	display: none;
}
.menu_item > * {
	border-bottom: 1px solid #000;
}
.cart_wrapper {
	border-width: 0;
	background-color: #fff;
}
.cart_btn {
	display: flex;
	align-items: center;
	margin-left: 20px;
}
.cart_btn span {
	font-size: 20px;
	font-weight: 600;
}
.contacts_item {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.contacts_item:not(:first-child)::before {
	content: "";
	width: 1px;
	height: 20px;
	display: block;
	background-color: #000;
	margin-right: 20px;
}
.contacts_item svg {
	margin-right: 5px 0 5px 10px;
}
.contacts_item_text {
	display: none;
}
.insta_btn {
	margin-left: auto;
	margin-right: 10px;
}
@media (min-width: 850px) {
	.insta_btn {
		display: none;
	}
}
@media (max-width: 1450px) {
	.menu_button {
		font-size: 14px;
	}
	.menu_button > *:not(:last-child) {
		margin-right: 55px;
	}
}
@media (max-width: 1220px) {
	.contacts {
		padding-left: 30px;
		padding-right: 30px;
	}
	.menu_button {
		font-size: 14px;
		margin-right: 0;
	}
	.menu_button > *:not(:last-child) {
		margin-right: 35px;
	}
	.contacts > * {
		padding: 5px 0 5px 20px;
	}
	.nav_menu {
		padding: 0 30px;
	}
}
@media (max-width: 1030px) {
	.menu_button {
		font-size: 12px;
	}
	.menu_button > *:not(:last-child) {
		margin-right: 25px;
	}
}
@media (max-width: 850px) {
	.contacts {
		display: none;
	}
	.menu_button {
		display: none;
	}
	.menu_burgerOpen {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 80px;
		left: 0;
		padding-top: 30px;
		width: 100%;
		background-color: #fff;
		border-top: 1px solid #eaeaea;
		font-size: 20px;
	}
	.menu_burgerClose {
		display: none;
	}
	.menu_burgerOpen > * {
		padding-bottom: 30px;
		padding-left: 30px;
	}
	.menu {
		justify-content: center;
		flex-wrap: wrap;
	}
	.menu_burger {
		display: block;
	}
	.logo span {
		position: static;
		font-size: 18px;
	}
	.logo svg {
		width: 20px;
		height: 20px;
	}
	.cart_wrapper {
		margin-left: auto;
	}
	.nav_menu {
		padding-right: 10px !important;
		padding-left: 10px;
	}
}
@media (max-width: 440px) {
	.menu_burger {
		width: 35px;
		height: 26px;
	}
}

/*----------------https://arnypraht.com/category/bags/--------------------*/

.note_wrapper {
	position: absolute;
	top: 120px;
	right: 10%;
	width: 400px;
	height: auto;
	padding: 20px 40px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
}
.note_title {
	font-size: 20px;
	border-bottom: 3px solid #000;
	padding-bottom: 10px;
	margin-bottom: 20px;
}
.discription_row {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.note_img {
	width: 100px;
}
.note_name {
	font-size: 18px;
	font-weight: 600;
	margin-left: 15px;
}
.note_price {
	font-size: 20px;
	font-weight: 600;
}
.button_row {
	margin-top: 20px;
}
.button_continue {
	background: transparent;
	border: 0;
	width: 190px;
	height: 50px;
	margin-right: 20px;
	cursor: pointer;
}
.button_continue > * {
	border-bottom: 3px solid #000;
	padding: 5px;
}
.button_cart {
	width: 190px;
	height: 50px;
	padding: 0;
	border-width: 0px;
	background-color: #000;
	cursor: pointer;
}
.button_cart > * {
	color: #fff;
}
.left_item {
	display: flex;
	align-items: center;
}
.background_card {
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	height: 1000px;
	opacity: 15%;
	background-color: #000;
}
.card_info {
	display: block;
}
.card_info_close {
	display: none;
}
