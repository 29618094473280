@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap");
.wrapper {
	position: relative;
	max-width: 1360px;
	padding: 0 50px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 100px;
	overflow-x: hidden;
	font-family: "Geologica", sans-serif;
	font-weight: 100;
}
.main_wrapper {
	display: flex;
}
.castom_title {
	display: block;
	font-size: 40px;
	padding: 40px 0 25px 0;
}
.castom_title_model {
	display: block;
	font-size: 40px;
	padding: 25px 0;
}
.model_wrapper {
	display: flex;
	margin-left: 7px;
}
.row_furniture .model_wrapper > *:first-child {
	margin-right: 7px !important;
}
.color_wrapper {
	display: flex;
	max-width: 980px;
	overflow-x: scroll;
	margin-left: 0px;
}
.color_second_wrapper {
	display: flex;
	max-width: 913px;
	display: block;
	overflow-x: hidden;
	margin-left: 0px;
	padding-right: 40px;
}
@media (max-width: 1460px) {
	.color_second_wrapper {
		max-width: 695px;
		padding-right: 20px;
	}
}
@media (max-width: 1200px) {
	.color_second_wrapper {
		max-width: 550px;
		padding-right: 25px;
	}
}
.circle_slider_color_left {
	display: block;
	width: 40px;
	height: 40px;
	background-color: #ffffff;
	position: absolute;
	top: 490px;
	left: 18px;
	z-index: 2;
	border-radius: 50px;
	opacity: 0.5;
}
.color_second_wrapper::-webkit-scrollbar {
}
.model_wrapper::-webkit-scrollbar {
}
body::-webkit-scrollbar {
	width: 11px; /* ширина scrollbar */
}
body::-webkit-scrollbar-track {
	background: rgb(239, 239, 239); /* цвет дорожки */
}
body::-webkit-scrollbar-thumb {
	background-color: rgb(119, 119, 119); /* цвет плашки */
	border-radius: 20px; /* закругления плашки */
	border: 3px solid rgb(239, 239, 239); /* padding вокруг плашки */
}
.color_wrapper::-webkit-scrollbar {
	height: 0;
}

.color_second_wrapper::-webkit-scrollbar {
	height: 0;
}
.color_second_wrapper::-webkit-scrollbar-track {
	background: rgb(239, 239, 239); /* цвет дорожки */
}
.color_second_wrapper::-webkit-scrollbar-thumb {
	background-color: rgb(119, 119, 119); /* цвет плашки */
	border-radius: 20px; /* закругления плашки */
	border: 1px solid rgb(239, 239, 239); /* padding вокруг плашки */
}

.model_wrapper::-webkit-scrollbar {
	height: 0px;
}

@media (max-width: 421px) {
	.model_wrapper::-webkit-scrollbar {
		height: 0;
	}
	.model_wrapper::-webkit-scrollbar-track {
		background: rgb(239, 239, 239); /* цвет дорожки */
	}
	.model_wrapper::-webkit-scrollbar-thumb {
		background-color: rgb(119, 119, 119); /* цвет плашки */
		border-radius: 20px; /* закругления плашки */
		border: 1px solid rgb(239, 239, 239); /* padding вокруг плашки */
	}
}

.image_block {
	position: -webkit-sticky;
	position: sticky;
	top: 122px;
	margin-top: 30px;
	margin-left: auto;
}
.image_block__image {
	position: relative;
}
.main_image {
	width: 350px;
}
.detail_image {
	width: 350px;
	position: absolute;
	top: 0;
	left: 0;
}
.button_form {
	display: block;
	margin: 0 auto;
	margin-top: 20px;
	width: 320px;
	height: 60px;
	font-size: 20px;
	background-color: #ffffff;
	color: #000000;
	cursor: pointer;
	border: 2px solid #000;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 200;
}
.button_form:hover {
	background-color: #000000;
	color: #ffffff;
}
@media (max-width: 1460px) {
	.image_block {
		margin-left: 20px;
		margin-right: auto;
	}
	.wrapper {
		max-width: 1100px;
	}
	.color_wrapper {
		max-width: 720px;
	}
}
@media (max-width: 1200px) {
	.wrapper {
		max-width: 900px;
	}
	.color_wrapper {
		max-width: 520px;
	}
}
@media (max-width: 1100px) {
	.main_image {
		width: 350px;
		height: 450px;
	}
	.detail_image {
		width: 350px;
		height: 450px;
	}
}
@media (max-width: 1000px) {
	.main_wrapper {
		flex-direction: column;
	}
	.form_wrapper {
		width: 100%;
	}
	.color_wrapper {
		max-width: 100%;
	}
	.color_second_wrapper {
		max-width: 100%;
	}
	.main_image {
		width: 100%;
		height: auto;
	}
	.detail_image {
		width: 100%;
		height: auto;
	}
	.button_form {
		width: 100%;
	}
	.image_block {
		margin-left: auto;
		margin-right: auto;
	}
}
@media (max-width: 600px) {
	.wrapper {
		overflow-y: hidden;
	}
	.model_wrapper {
		overflow-x: scroll;
	}
	.wrapper {
		max-width: 1350px;
		padding: 0 20px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 100px;
	}
	.button_form {
		font-size: 16px;
		height: 50px;
	}
}
.form_image_block {
	position: relative;
	width: auto;
	margin-right: 10px;
	width: 50%;
}
.image_block_header {
	text-align: center;
	margin-top: 0;
	font-weight: 200;
	font-size: 24px;
}
.form_image_block__image {
	width: 250px;
}
.form_main_image {
	width: 250px;
}
.form_detail_image {
	width: 250px;
	position: absolute;
	top: 0;
	left: 0;
}
.form_button_form {
	background-color: #fff;
	width: 100%;
	height: 50px;
	font-size: 20px;
	cursor: pointer;
	margin-top: 20px;
}
.form_button_form:hover {
	background-color: #000000;
	color: #fff;
}
.form_body {
	display: flex;
	flex-direction: column;
	-webkit-box-shadow: 0px 0px 9px 2px rgba(34, 60, 80, 0.42);
	-moz-box-shadow: 0px 0px 9px 2px rgba(34, 60, 80, 0.42);
	box-shadow: 0px 0px 9px 2px rgba(34, 60, 80, 0.42);
}
.form_label label {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding-bottom: 10px;
	font-size: 20px;
}
.form_label input {
	display: block;
	width: 100%;
	height: 25px;
	margin-top: 5px;
}
.form_back {
	position: absolute;
	top: -100px;
	left: -800px;
	width: 1000%;
	height: 100%;
	background-color: #bbbbbb;
	opacity: 50%;
	padding-bottom: 100px;
}
@media (max-width: 1100px) {
	.form_main_wrapper {
		top: 2%;
		padding: 50px;
		width: 250px;
	}
	.form_image_block {
		position: relative;
		width: 250px;
	}
	.form_image_block__image {
		width: 250px;
	}
	.form_main_image {
		width: 250px;
	}
	.form_wrapper {
		padding-right: 30px;
	}
}
@media (max-width: 800px) {
	.form_main_wrapper {
		top: 2%;
		padding: 50px;
		width: 550px;
	}
	.form_image_block {
		position: relative;
		width: 300px;
	}
	.form_image_block__image {
		width: 300px;
	}
	.form_main_image {
		width: 300px;
	}
	.form_detail_image {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	label {
	}
	.label {
		width: auto;
	}
	.input {
	}
	.form_close_button {
		left: 93%;
	}
}

@media (max-width: 600px) {
	.form_main_wrapper {
		width: 78%;
		margin: 0 30px;
		padding: 30px;
	}
}
@media (max-width: 550px) {
	.form_main_wrapper {
		width: 75%;
		margin: 0 40px;
		padding: 30px;
	}
	.form_close_button {
		left: 92%;
	}
}
@media (max-width: 510px) {
	.form_main_wrapper {
		width: 75%;
		margin: 0 30px;
		padding: 25px;
	}
	.form_close_button {
		top: 0;
		left: 92%;
	}
}
@media (max-width: 450px) {
	.form_close_button {
		left: 91%;
	}
}
@media (max-width: 410px) {
	.form_main_wrapper {
		width: 70%;
		margin: 0 30px;
		padding: 25px;
	}
	.form_close_button {
		left: 90%;
	}
}
@media (max-width: 350px) {
	.form_main_wrapper {
		width: 65%;
		margin: 0 35px;
		padding: 25px;
	}
	.form_close_button {
		top: 0%;
		left: 88%;
	}
}
@media (max-width: 340px) {
	.form_main_wrapper {
		width: 65%;
		margin: 0 30px;
		padding: 25px;
	}
	.form_close_button {
		top: 0%;
		left: 88%;
	}
}

.form_body {
	position: fixed;
	z-index: 99999;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	width: auto;
	border: 1px solid #4e4e4e;
	background-color: #fff;
	padding: 20px;
}
.form_main {
	display: flex;
	flex-direction: row;
}
.form_header {
	font-weight: 100;
	font-size: 30px;
	margin: 0;
	width: 100%;
	text-align: center;
	padding-bottom: 20px;
}
.first_part_form label {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	font-size: 16px;
	margin-bottom: 10px;
}
.first_part_form label:last-child {
	display: flex;
	flex-direction: column;
}
.first_part_form .form_label input {
	display: block;
	width: 100%;
	height: 20px;
	margin-top: 5px;
	border: 1px solid #838383;
}
.input {
	display: inline-block;
	font-weight: 300;
}
textarea {
	width: 100%;
	margin-top: 5px;
	border: 1px solid #000;
}
.form_button {
	width: 200px;
	height: 50px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	background-color: #fff;
	border: 1px solid #000;
	cursor: pointer;
	margin-top: 30px;
}
.form_button:hover {
	background-color: #000;
	color: #fff;
}
.form_close_button {
	border: none;
	background: none;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
}
.checkbox {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap !important;
}
.checkbox input {
	display: inline;
	width: 30px;
}
.checkbox span {
	padding-left: 10px;
}
input[type="checkbox"] {
	border-radius: 0;
	color: #000;
}
.back {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	display: none;
	width: 100%;
	height: 100%;
	background-color: #747474;
	opacity: 0.6;
}
.first_part_form {
	max-width: 300px;
}
.form_row {
	padding-bottom: 5px;
	font-size: 16px;
	display: flex;
	justify-content: space-between;
}
.form_info_main {
	width: 100%;
}
.form_row:last-child {
	border-top: 1px solid #000;
	margin-top: 10px;
	padding-top: 10px;
	font-size: 16px;
	width: 100%;
}
.form_row:last-child .label {
}
@media (min-width: 1150px) {
	.form_info {
		max-width: 250px;
	}
}
.form_wrapper {
	display: flex;
	flex-direction: row;
	font-size: 14px;
}
.label {
	font-weight: 200;
}
@media (max-width: 650px) {
	.form_body {
		width: auto;
	}
	.form_header {
		font-size: 20px;
		font-weight: 600;
	}
	label {
		font-size: 14px;
	}
}
@media (max-width: 630px) {
	.form_body {
		width: 85%;
	}
	.form_main_wrapper {
		top: 2%;
		padding: 50px;
		width: 550px;
	}
	.form_image_block {
		position: relative;
		width: 200px;
	}
	.form_image_block__image {
		width: 200px;
	}
	.form_main_image {
		width: 200px;
	}
	.form_detail_image {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	.input {
		margin-left: 0;
	}
	.label {
		width: auto;
	}
	.form_row {
		font-size: 16px;
	}
	.form_button {
		width: 150px;
		height: 30px;
		margin-top: 10px;
	}
	.form_label textarea {
		height: 70px;
	}
	.first_part_form .form_label {
		font-size: 14px;
		margin-bottom: 0;
	}
	.checkbox {
		margin-top: 20px;
	}
	.checkbox span {
		font-size: 12px;
	}
	.form_label input {
		height: 15px !important;
	}
}
@media (max-width: 430px) {
	.form_image_block {
		position: relative;
		width: 150px;
	}
	.form_image_block__image {
		width: 150px;
	}
	.form_main_image {
		width: 150px;
	}
	.form_button {
		width: 110px;
		height: 25px;
		margin-top: 0;
	}
	.first_part_form .form_label {
		font-size: 11px;
		margin-bottom: 0;
	}
	.checkbox span {
		font-size: 10px;
	}
	.checkbox {
		margin-top: 10px;
	}
	.form_label textarea {
		height: 40px;
	}
	.form_body {
		padding: 10px;
	}
	.checkbox span {
		font-size: 9px;
	}
	.form_row {
		font-size: 16px;
	}
}
.info_wrapper {
	margin-top: 30px;
}
@media (max-width: 460px) {
	.info_header h1 {
		font-size: 24px;
	}
}
.info {
	font-size: 18px;
	font-weight: 300;
	border-bottom: 1px solid #000;
	padding-bottom: 10px;
	margin-bottom: 10px;
}
@media (min-width: 1000px) {
	.info {
		max-width: 350px;
	}
}
