.model_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    background: none;
    border: 0px;
    font-family: "Geologica", sans-serif;
	font-weight: 100;
    font-size: 18px;
    padding-bottom: 20px;
    margin-left: 25px;
}
.image_model {
    width: 150px;
    height: 150px;
    border-radius: 200px;
}
.name {
    margin-bottom: 20px;
}