.card {
	display: flex;
	align-items: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 25px;
	justify-content: space-between;
}
.card:not(:last-child) {
	border-bottom: 1px solid #000000;
}
.card > * {
	margin-bottom: 20px;
	width: auto;
	height: 200px;
}
.card_description {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
.card_button {
	margin-left: auto;
	padding: 20px;
	border-width: 0;
	background-color: transparent;
	font-size: 30px;
	font-weight: 700;
	cursor: pointer;
}
.button_block {
	display: flex;
	align-items: center;
	height: 50px;
	justify-content: center;
	font-size: 20px;
}
.price_block {
	display: flex;
	align-items: center;
	font-size: 20px;
}
.delete_btn {
	background-color: transparent;
	border-width: 0;
	font-weight: 600;
	font-size: 25px;
	color: #ff0000;
	display: flex;
	align-items: start;
	cursor: pointer;
}
