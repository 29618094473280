.model_wrapper {
    margin-right: 20px;
    cursor: pointer;
    background: none;
    border: 0px;
    font-family: "Geologica", sans-serif;
	font-weight: 100;
    font-size: 18px;
    padding-bottom: 20px;
}
.image_model {
}
.name {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 94px;
    height: 94px;
    border-radius: 200px;
    border: 3px solid black;
}