@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
.catalog_wrapper {
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  min-width: 320px;
  position: relative;

  font-family: 'Geologica', sans-serif;
  font-weight: 100;
}
.catalog > *:nth-child(7),
.catalog > *:last-child {
  visibility: hidden;
}
@media (max-width: 1070px) {
  .catalog > *:nth-child(7),
  .catalog > *:last-child {
    display: none;
  }
}
@media (max-width: 460px) {
  .catalog > *:nth-child(6) {
    display: none;
  }
}
.catalog {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.catalog_title {
  display: block;
  font-size: 40px;
  padding: 25px 0 25px 0;
}
@media (max-width: 1475px) {
  .catalog_wrapper {
    max-width: 1150px;
    padding: 0 30px;
  }
  .catalog {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 1100px) {
  .catalog_wrapper {
    max-width: 950px;
    padding: 0 20px;
  }
}
@media (max-width: 970px) {
  .catalog_wrapper {
    max-width: 850px;
  }
}
@media (max-width: 820px) {
  .catalog_wrapper {
    max-width: 650px;
  }
}
/*-----------------------------------------------------------------*/

.note_wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 122px;
  margin-left: auto;
  right: 100px;
  width: 400px;
  height: 300px;
  background-color: #eaeaea;
  z-index: 9999;
}
.info_wrapper {
  margin-top: 30px;
}
@media (max-width: 460px) {
  .info_header h1 {
    font-size: 24px;
  }
}
