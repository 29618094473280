@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
.wrapper {
	display: block;
	border-top: 1px solid #e6e6e6;
	background-color: #f8f8f8;
	
    font-family: "Geologica", sans-serif;
	font-weight: 100;
}
.copyrating {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	border-top: 1px solid #e6e6e6;
	background-color: #f0f0f0;
}
.copyrating span{
	margin-right: 100px;
}
.copyrating a{
	text-decoration: underline;
}
.copyrating a:not(:last-child){
	margin-right: 100px;
}
@media(max-width:1000px) {
	.copyrating span{
		margin-right: 50px;
	}
	.copyrating a:not(:last-child){
		margin-right: 50px;
	}
}
@media(max-width:700px) {
	.copyrating{
		flex-direction: column-reverse;
		padding: 40px 0;
	}
	.copyrating span{
		margin-right: 0;
	}
	.copyrating a{
		margin-right: 0!important;
		margin-bottom: 10px;
	}
}
.menu_wrapper {
	width: auto;
}
.menu_wrapper li {
	width: auto;
	line-height: 30px;
}
.menu_wrapper li span {
}
.footer_wrapper {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	max-width: 1920px;
	margin: 0 auto;
}
.footer_header {
	text-align: start;
}
.menu_wrapper ul {
	list-style: none;
	padding-left: 0;
}
.logo {
	display: flex;
	font-family: "Times New Roman", Times, serif;
	align-items: center;
	color: #000;
	text-align: center;
	font-size: 20px;
	line-height: 0.8;
}
.logo svg {
	margin-right: 5px;
}
.contacts_item {
	display: flex;
	justify-content: start;
	padding: 5px 0;
}
.contacts_item span {
	margin-left: 10px;
}
.menu_wrapper::before {
	content: "";
	position: absolute;
	top: 35%;
	margin-left: -30px;
	width: 1px;
	height: 80px;
	background-color: #d8d8d8;
}
.about_wrapper::before {
	content: "";
	position: absolute;
	top: 35%;
	margin-left: -30px;
	width: 1px;
	height: 80px;
	background-color: #d8d8d8;
}
@media (max-width: 900px) {
	.menu_wrapper::before {
		margin-left: -10px;
	}
	.about_wrapper::before {
		margin-left: -10px;
	}
	.footer_wrapper {
		font-size: 14px;
	}
	.menu_wrapper li {
		line-height: 25px;
	}
}
@media (max-width: 700px) {
	.logo {
		display: none;
	}
	.menu_wrapper::before {
		display: none;
	}
	.about_wrapper::before {
		margin-left: -20px;
	}
}
@media (max-width: 580px) {
	.about_wrapper::before {
		margin-left: -10px;
	}
}
@media (max-width: 550px) {
	.about_wrapper::before {
		display: none;
	}
	.footer_wrapper {
		flex-direction: column;
	}
	.footer_wrapper > * {
		text-align: center;
	}
	.footer_header {
		margin-top: 10px;
		margin-bottom: 5px;
		text-align: center;
		background-color: #cbcbcb;
		padding: 5px 0;
		font-weight: 400;
	}
	.contacts_item {
		justify-content: center;
	}
	.contacts_wrapper {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.contacts_item svg {
		margin: 0 15px;
	}
	.item_phone {
		width: 35px;
		height: 35px;
	}
	.item_mail {
		width: 40px;
		height: 40px;
	}
	.item_insta {
		width: 35px;
		height: 35px;
	}
	.contacts_item{
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 7px;
		margin-top: 10px;

	}
	.contacts_item span:last-child {
		display: none;
	}
}
@media (max-width: 350px) {
	.footer_header {
		font-size: 14px;
	}
}
.about_wrapper {
	margin-bottom: 20px;
}
.contacts_item span:first-child {
	padding: 0;
	margin: 0;
	width: 25px;
	text-align: center;
}