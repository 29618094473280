@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap");

.card {
	width: 300px;
	height: 421px;
	position: relative;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 50px;

	font-family: "Geologica", sans-serif;
	font-weight: 100;
}
.image_div {
	width: 300px;
	height: 375px;
	position: absolute;
}
.card_image {
	width: 300px;
	cursor: pointer;
}
.card_image2 {
	width: 300px;
	position: absolute;
	left: 0;
	z-index: -9999;
	cursor: pointer;
}
.card_description {
	position: absolute;
	display: flex;
	flex-direction: row;
	top: 385px;
}
.card_button {
	margin-left: auto;
	width: 100px;
	height: 100%;
	border-radius: 0;
	background-color: #fff;
	cursor: pointer;
	position: absolute;
	left: 200px;
	font-size: 12px;
	font-weight: 600;

	font-family: "Geologica", sans-serif;
}
.card_button:hover {
	color: #fff;
	background-color: #000;
}
.card_name {
	font-weight: 400;
}
.card_price {
	text-decoration: line-through;
	margin-left: 10px;
}
@media ((max-width: 1470px) and (min-width:1100px)),
	(max-width: 970px) and (min-width: 820px),
	(max-width: 660px) and (min-width: 560px) {
	.card {
		width: 250px;
		height: 365px;
	}
	.card_image {
		width: 250px;
	}
	.card_image2 {
		width: 250px;
	}
	.card_description {
		top: 323px;
	}
	.card_button {
		left: 150px;
	}
	.image_div {
		width: 250px;
		height: 312px;
		position: absolute;
	}
}
@media (max-width: 560px) and (min-width: 460px) {
	.card {
		width: 200px;
		height: 300px;
	}
	.card_image {
		width: 200px;
	}
	.card_image2 {
		width: 200px;
	}
	.card_description {
		top: 260px;
	}
	.card_button {
		left: 100px;
	}
	.image_div {
		width: 200px;
		height: 250px;
		position: absolute;
	}
	.card_name {
		font-size: 14px;
	}
}
.color {
	margin-left: 5px;
	height: 20px;
	overflow-y: hidden;
}
