.App {
  text-align: center;
}
@media(min-width:400px) {
  .scroll-to-top {
    width: 70px!important;
    height: 70px!important;
    opacity: 0.6;
  }
  .scroll-to-top svg{
    width: 30px!important;
    height: 30px!important;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

.app-wrapper-container {
  min-height: calc(100vh - 269px);
}