@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
.divName {
  width: 100%;
  height: 100px;
  display: block;
  background-color: #aeaeae;
  font-family: Monserrat;
}
.divName2 {
  width: 100%;
  height: 100px;
  display: block;
  background-color: #eaeaea;
}
.image_wrapper {
  display: flex;
  flex-direction: row;
}
.image_wrapper > * {
  width: 50%;
}
