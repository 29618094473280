.wrapper {
  text-align: center;
  padding: 200px 0;
  font-size: 13px;
  font-family: 'Geologica', sans-serif;
  font-weight: 100;
}
.wrapper h1 {
  font-family: 'Geologica', sans-serif;
  font-weight: 100;
}
@media (min-width: 560px) {
  .wrapper {
    padding: 200px;
  }
}
