.description_wrapper {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 250px;
}
@media(max-width: 1100px) {
    .description_wrapper {
        height: 260px;
    }
}
@media(max-width: 840px) {
    .description_wrapper {
        font-size: 16px;
        height: 230px;
    }
}
@media(max-width: 670px) {
    .description_wrapper {
        font-size: 14px;
        height: 230px;
    }
}
@media(max-width: 600px) {
    .description_wrapper {
        font-size: 16px;
    }
}