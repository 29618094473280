@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
.category_list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 30px;
    font-family: "Geologica", sans-serif;
	font-weight: 100;
}
@media(max-width:520px) {
    .category_list {
        flex-direction: column;
        align-items: center;
    }
}