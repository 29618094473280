.cart_wrapper {
	max-width: 1360px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 100px;
	min-width: 320px;
	background-color: #ffffff;
	padding-bottom: 70px;
}
.cart_title {
	display: block;
	width: 100%;
	padding: 20px 20px;
	margin-bottom: 20px;
	font-size: 50px;
	border-bottom: 1px solid #000;
}
.cart_body {
	display: flex;
	flex-direction: row;
	font-weight: 600;
}
.body_result {
	padding: 20px;
	min-width: 400px;
	max-height: 200px;
	background-color: #e4e4e4;
	margin-left: auto;
}
.body_result > *:not(:last-child) {
	margin-bottom: 20px;
}
.result_price {
	font-size: 25px;
	font-weight: 700;
	margin-left: auto;
}
.checkout_btn {
	width: 100%;
	height: 70px;
	border-width: 0;
	color: #fff;
	background-color: #000;
	font-size: 20px;
}
.result_title {
	font-size: 30px;
	border-bottom: 2px solid #000;
	display: block;
	width: 100%;
	padding-bottom: 20px;
}
.result_summ {
	font-size: 20px;
}
.body_products {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 20px;
	margin-right: 20px;
	margin-bottom: 20px;
}
.empty_cart {
	font-size: 30px;
	margin-top: 100px;
}
