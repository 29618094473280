@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap");
.wrapper {
	max-width: 1460px;
	margin-left: auto;
	margin-right: auto;
	height: auto;
	display: flex;
	flex-direction: row;
	padding-bottom: 100px;
	font-family: "Geologica", sans-serif;
	font-weight: 100;
}
.description_wrapper {
	margin: 0 50px;
}

.about_brand_title {
	display: block;
	padding: 25px 0px;
	font-size: 40px;
	margin: 0;
	font-weight: 100;
}

.image_wrapper {
	width: 100%;
    display: flex;
    flex-direction: row;
	align-items: center;
	margin-top: 30px;


}
.image_wrapper >*:nth-child(2n){
	max-width: 36%;
	width: auto;
	margin: 0 1%;
}
.image_wrapper >*:nth-child(2n+1){
	max-width: 31%;
	height: 100%;
	width: auto;
}
.text_wrapper {
	font-size: 16px;
	line-height: 2;
}
.text_wrapper p:not(:last-child) {
	padding-left: 11px;
}
.citate{
	margin-top: 50px;
	line-height: 2;
	background-color: #e9e9e9;
	border-radius: 10px;
	padding: 20px;
}
.citate p {
	padding: 0;
	margin: 0;
	font-size: 17px;
	font-weight: 300;
}
.citate p:last-child {
	text-align: right;
	font-weight: 100;
	margin-top: 15px;
	margin-left: auto;
}
@media(max-width: 750px) {
	.description_wrapper {
		margin: 0 15px;
	}
	.citate p {
		font-size: 16px;
	}
}