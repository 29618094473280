@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap");
.card_wrapper {
	max-width: 1360px;
	height: 100%;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 100px;
	display: flex;
	flex-direction: column;

	font-family: "Geologica", sans-serif;
	font-weight: 100;
}
@media (max-width: 1000px) {
	.card_wrapper {
		padding: 0 20px;
	}
}
.button_back {
	display: flex;
	align-items: center;
	width: 120px;
	margin-bottom: 20px;
	border: 0;
	background: none;
	padding: 0;
	cursor: pointer;
	font-size: 16px;
	font-weight: 100;
}
.button_back svg {
	margin-right: 5px;
}
.card {
	display: flex;
	padding-bottom: 70px;
}
.description_block {
	height: 500px;
	padding-left: 50px;
	margin-top: 0px;
}
.card_title {
	font-size: 40px;
	font-weight: 200;
}
@media (max-width: 1050px) {
	.card_title {
		font-size: 30px;
	}
	.description_block {
		margin-top: 0;
	}
}
@media (max-width: 400px) {
	.card_title {
		font-size: 25px;
	}
}
.card_price_wrapper {
	margin-top: 20px;
	font-size: 25px;
	font-weight: 300;
}
.card_price {
	text-decoration: line-through;
	margin-left: 10px;
}
.choose_color {
	display: flex;
	flex-direction: row;
	margin-top: 5px;
}
.color_title {
	margin-top: 20px;
	font-weight: 400;
}
.choose_color > * {
	display: block;
	width: 20px;
	height: 20px;
	border-radius: 100px;
	cursor: pointer;
}
.choose_color > *:not(:last-child) {
	margin-right: 10px;
}
.offer_button {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	font-size: 16px;
	border-radius: 0px;
	width: 200px;
	height: 50px;
	border-width: 0px;
	cursor: pointer;
	background-color: #fff;
	color: #000;
	border: 2px solid #000;
	font-family: "Geologica", sans-serif;
	font-weight: 400;
}
.offer_button:hover {
	background-color: #000;
	color: #fff;
}
.offer_button a:hover {
	background-color: #000;
	color: #fff;
}
.card_description {
	margin-top: 20px;
}
@media (max-width: 1000px) {
	.description_block {
		padding-left: 20px;
	}
}
@media (max-width: 840px) {
	.card_description {
		margin-top: 0;
	}
	.description_block {
		height: 500px;
		padding-left: 20px;
		margin-top: 0px;
	}
	.card_price {
		margin-top: 0;
	}
}
.color_wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}
.color_text {
	font-size: 20px;
	font-weight: 300;
}
.color_circle {
	display: block;
	width: 30px;
	height: 30px;
	border-radius: 50px;
	margin-top: 10px;
}
@media (max-width: 630px) {
	.card_title {
		font-size: 20px;
		margin-bottom: 20px;
	}
	.card_price {
		font-size: 20px;
	}
	.offer_button {
		margin-top: 10px;
	}
	.color_text {
		font-size: 16px;
		font-weight: 300;
	}
	.color_wrapper {
		margin: 0;
	}
}
@media (max-width: 600px) {
	.card {
		flex-wrap: wrap;
	}
	.description_block {
		padding-left: 0;
		margin-top: 20px;
	}
}
