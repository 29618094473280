@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap");
.wrapper {
	max-width: 1360px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: row;
	padding-bottom: 100px;
	line-height: 2;
	font-family: "Geologica", sans-serif;
	font-weight: 100;
}
.menu {
	min-width: 260px;
}
.list_menu {
	text-decoration: none;
	list-style: none;
	position: -webkit-sticky;
	position: sticky;
	top: 130px;
}
.item_link .menu_item {
	padding: 10px 10px;
	border-top: 1px solid #000;
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	font-weight: 200;
}
.item_link:last-child .menu_item {
	border-bottom: 1px solid #000;
}
.item_link .menu_item:hover {
	background-color: #e4e4e4;
}
.content_part {
	padding-top: 122px;
	margin-top: -122px;
}
.content_wrapper {
	position: relative;
	padding: 0 40px;
}
.space {
	display: block;
	height: 50px;
}
.header {
	margin-top: 50px;
	text-align: center;
	font-weight: 200;
}
.header_main {
	text-align: center;
	padding-bottom: 15px;
	border-bottom: 1px solid #000;
	font-weight: 200;
}
.reviews_button {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 200px;
	height: 50px;
	margin-top: 40px;
	border-radius: 0;
	border: 2px solid #000;
	background-color: #fff;
	cursor: pointer;

	font-family: "Geologica", sans-serif;
	font-weight: 300;
}
.reviews_button:hover {
	background-color: #000;
	color: #fff;
}
.click_link {
	color: blue;
}
.form_body {
	position: fixed;
	z-index: 99999;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	width: 500px;
	border: 1px solid #4e4e4e;
	background-color: #fff;
	padding: 20px;
	-webkit-box-shadow: 0px 0px 9px 2px rgba(34, 60, 80, 0.42);
	-moz-box-shadow: 0px 0px 9px 2px rgba(34, 60, 80, 0.42);
	box-shadow: 0px 0px 9px 2px rgba(34, 60, 80, 0.42);
}
.form_header {
	font-weight: 100;
	font-size: 30px;
	margin: 0;
	width: 100%;
	text-align: center;
}
label {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	font-size: 16px;
	margin-top: 10px;
}
label:last-child {
	display: flex;
	flex-direction: column;
}
input {
	display: block;
	width: 100%;
	height: 25px;
	margin-top: 5px;
	border: 1px solid #838383;
}
textarea {
	font-size: 16px;
	margin-top: 10px;
	border: 1px solid #838383;
}
.form_button {
	width: 200px;
	height: 50px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	background-color: #fff;
	border: 1px solid #000;
	cursor: pointer;
}
.form_button:hover {
	background-color: #000;
	color: #fff;
}
.form_close_button {
	border: none;
	background: none;
	position: absolute;
	top: 0;
	right: 0;
	margin-right: -5px;
	cursor: pointer;
}
.checkbox {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}
.checkbox input {
	display: inline;
	width: 30px;
}
.checkbox span {
	padding-left: 10px;
}
input[type="checkbox"] {
	border-radius: 0;
	color: #000;
}
.back {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	display: none;
	width: 100%;
	height: 100%;
	background-color: #747474;
	opacity: 0.6;
}
@media (max-width: 875px) {
	.menu {
		font-size: 14px;
		min-width: 190px;
	}
	.content_wrapper {
		padding: 0 20px;
	}
	.list_menu {
		padding-left: 20px;
	}
}
@media (max-width: 650px) {
	.form_body {
		width: 80%;
	}
	.form_header {
		font-size: 20px;
		font-weight: 600;
	}
	label {
		font-size: 14px;
	}
}
@media (max-width: 550px) {
	.menu {
		font-size: 14px;
		min-width: 210px;
		padding-right: 20px;
	}
	.content_wrapper {
		padding: 0 20px;
	}
	.wrapper {
		flex-direction: column;
	}
	.content_part {
		padding-top: 0;
		margin-top: 0;
	}
}
