@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
.slide_wrapper {
  display: block;
  width: 100%;
  height: 1000px;
  position: relative;
  background: linear-gradient(to right, #f7f7f7, #919191);
  font-family: 'Geologica', sans-serif;
  font-weight: 100;
}
.slide_description {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 45%;
  right: 0;
  top: 30%;
  text-align: center;
  color: #fff;
  width: 450px;
  padding-left: 20px;
}
@media (max-width: 1420px) {
  .slide_description {
    width: 340px;
  }
}
.description_background {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 450px;
  height: 400px;
  opacity: 0.2;
  border-radius: 20px;
  z-index: 0;
}
.slide_image {
  width: 100%;
  height: 1000px;
  object-fit: cover;
  object-position: top;
  filter: brightness(65%);
}
.description_title {
  font-size: 50px;
  font-family: 'Times New Roman', sans-serif;
  font-weight: 100;
  margin: 20px 0;
}
.title_icon {
  margin-right: 15px;
}
.description_subtitle {
  font-size: 18px;
  font-family: 'Geologica', sans-serif;
  font-weight: 100;
  margin: 0;
  line-height: 1.3;
}
.catalog_button {
  display: none;
  width: 200px;
  height: 50px;
  border-width: 0;
  background-color: #fff;
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;

  font-family: 'Geologica', sans-serif;
  font-weight: 100;
}
.catalog_button:hover {
  background-color: #000;
  color: #fff;
}

.slide_wrapper {
  max-width: 100%;
  height: 750px;
}

.slide_image {
  max-width: 100%;
  height: auto;
}
.image_wrapper {
  display: block;
  width: 100%;
  height: 1000px;
  overflow: hidden;
}
@media (max-width: 1920px) {
  .slide_wrapper {
    max-width: 100%;
    height: 750px;
  }
}
@media (max-width: 1650px) {
  .slide_wrapper {
    max-width: 100%;
    height: 650px;
  }
  .description_title {
  }
}
@media (max-width: 1377px) {
  .slide_wrapper {
    max-width: 100%;
    height: 550px;
  }
  .slide_description {
    top: 25%;
  }
}
@media (max-width: 1200px) {
  .slide_wrapper {
    max-width: 100%;
    height: 500px;
  }
}
@media (max-width: 1100px) {
  .slide_wrapper {
    max-width: 100%;
    height: 450px;
  }

  .slide_description {
    top: 15%;
  }
}
@media (max-width: 1050px) {
  .description_title {
    font-size: 50px;
  }
  .description_subtitle {
    font-size: 16px;
  }
  .slide_description {
    width: 300px;
    top: 25%;
  }
}
@media (max-width: 950px) {
  .slide_wrapper {
    max-width: 100%;
    height: 400px;
  }
}
@media (max-width: 800px) {
  .slide_wrapper {
    max-width: 100%;
    height: 350px;
  }
}
@media (max-width: 600px) {
  .slide_wrapper {
    max-width: 100%;
    height: 300px;
  }
}
@media (max-width: 850px) {
  .slide_description {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    left: 0;
    right: 0;
    top: 23%;
    bottom: 0;
    padding-left: 0px;
  }
  .slide_image {
    filter: brightness(70%);
  }
}
@media (max-width: 600px) {
  .slide_description {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 30%;
    padding-left: 0px;
    max-width: 300px;
  }
  .description_title {
    font-size: 30px;
    margin: 0;
  }
  .description_subtitle {
    font-size: 14px;
  }
}
@media (max-width: 370px) {
  .slide_description {
    left: 0;
    max-width: 280px;
  }
  .description_title {
  }
  .description_subtitle {
    font-size: 12px;
  }
}
.swiper-button-next {
  color: red;
  background-color: #5c4e4e;
  width: 100px;
  height: 100px;
}
