.slider_wrapper {
  width: 400px;
}
.main_image {
  width: 400px;
}
.image_bar {
  display: flex;
  justify-content: center;
}
.image_bar > *:not(:last-child) {
  margin-right: 7px;
}
.image_bar button {
  border-width: 0;
  cursor: pointer;
  padding: 0;
  background: none;
}
.image_bar img {
  width: 60px;
}
@media (max-width: 840px) {
  .slider_wrapper {
    width: 300px;
  }
  .main_image {
    width: 300px;
  }
  .image_bar img {
    width: 45px;
  }
}
@media (max-width: 600px) {
  .slider_wrapper {
    width: 100%;
  }
  .main_image {
    width: 100%;
  }
  .image_bar img {
    width: 85px;
  }
}
@media (max-width: 560px) {
  .image_bar img {
    width: 90%;
  }
}
@media (max-width: 440px) {
  .image_bar {
    display: none;
  }
}
.main_image_wrapper {
  position: relative;
}
.left_btn {
  position: absolute;
  top: 50%;
  border-width: 0;
  background: none;
  font-size: 30px;
  font-weight: 600;
  color: #646464;
  cursor: pointer;
}
.right_btn {
  position: absolute;
  top: 50%;
  right: 0;
  border-width: 0;
  background: none;
  font-size: 30px;
  font-weight: 600;
  color: #646464;
  cursor: pointer;
}
